import React from 'react';
import '../styles/help.css';
const Help = () => {
	return (
		<div className="Help">
			<div className="Help-Options">
				<ul className="Help-Option-Ul">
					<li>
						<b>help</b>
					</li>
					<li>
						<b>about</b>
					</li>
					<li>
						<b>skills</b>
					</li>
					<li>
						<b>experience</b>
					</li>
					<li>
						<b>clear </b>
					</li>
					<li>
						{' '}
						<b>all</b>
					</li>
				</ul>
				<ul className="Help-Option-Ul-Mini">
					<li>
						<b>help</b>
					</li>
					<li>
						<b>about</b>
					</li>
					<li>
						<b>skills</b>
					</li>
					<li>
						<b>experience</b>
					</li>
					<li>
						<b>clear </b>
					</li>
					<li>
						<b>all</b>
					</li>

				</ul>
			</div>
		</div>
	);
};
export default Help;
