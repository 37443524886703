import React from 'react';
import '../styles/about.css';
const About = () => {
	return (
		<div className="About">
			<div className="About-Intro">
				<p className="Title">About:</p>
				<ul>
					<li>
						Experienced <b>Python Backend Developer</b> with a strong background in <b>Informatics and Computer Science</b>. 
					</li>
					<li>Skilled in addressing complex challenges within blockchain projects, contributing to effective solutions.</li>
					<li>Experienced in designing and implementing microservices architectures to enhance <b>scalability</b> and <b>flexibility</b> within software systems.</li>
				</ul>
			</div>
		</div>
	);
};
export default About;
