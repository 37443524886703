import React from 'react';
import '../styles/skills.css';
const Skills = () => {
	return (
		<div className="Skills">
			<h3 className="Title">Expertise</h3>
			<div className="Skills" />
			<ul className="Skills-List">
				<li>
					<p className="Skill-Title">Django Rest Framework</p>
				</li>
				<li>
					<p className="Skill-Title">Python</p>

				</li>
				<li>
					<p className="Skill-Title">Docker</p>
				</li>
				<li>
					<p className="Skill-Title">Redis</p>
				</li>
				<li>
					<p className="Skill-Title">Celery</p>
				</li>
			</ul>
		</div>
	);
};
export default Skills;
