import React from 'react';

import '../styles/work.css';
const Work = () => {
	return (
		<div className="Work">
			<div className="Work-Intro">
				<p className="Title">Experience</p>

				<p className="Work-Job">Rock'n'Block, Saint-Petersburg | Remote</p>

				<p className="Work-Date">03/2023 – present</p>
				<p className="Work-Position">Backend Developer</p>
				<ul className="Work-Detail">
					<li>
						Development of blockchain projects of various complexity levels from scratch
					</li>
					<li>
						Support for existing projects, consultations
					</li>
					<li>
						Working on marketplaces, decentralized exchanges, and business logic for applications
					</li>
					<li>Practiced analytical approached to problem-solving and trouble shooting.</li>
				</ul>
				<p className="Work-Job">Saint Petersburg State Marine Technical University | On-site</p>
				<p className="Work-Date">04/2022 – 04/2023</p>
				<p className="Work-Position">Python developer</p>
				<ul className="Work-Detail">
					<li>
						Developing Telegram bots
					</li>
					<li>
						Creating a plastic usage tracking system for 3D printers
					</li>
					<li>
						Managing a Linux servers
					</li>
					<li>
						Administering email servers
					</li>
				</ul>


			</div>
		</div>
	);
};
export default Work;
